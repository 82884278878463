@font-face {
  font-family: "PP Neue Montreal";
  src: local("PP Neue Montreal"),
    url(./fonts/PPNeueMontreal-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "PP Neue Montreal";
  font-weight: 300;
  src: local("PP Neue Montreal"),
    url(./fonts/PPNeueMontreal-Book.ttf) format("opentype");
}

@font-face {
  font-family: "PP Neue Montreal";
  font-weight: 500;
  src: local("PP Neue Montreal"),
    url(./fonts/PPNeueMontreal-Medium.ttf) format("opentype");
}

body {
  margin: 0;
  font-family: PP Neue Montreal, Helvetica Neue, Helvetica -apple-system,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121421;
  color: white;
}

/* used for markdown */
p,
li {
  color: #a9a9a9;
}

strong {
  color: white;
}

a {
  color: white;
}
